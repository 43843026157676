$font-awesome: FontAwesome;

//colors
$white: #fff;
$black: #000;
$primary: #357fef;
$secondary: #4e4e4e;
$light:#13B8EA;
$dark: #4E56F3;

$bg-light:#f5f5f8;
$bg-light1:#d0edff;
$bg-light2:#f1e7e6;
$bg-light3:#bfbfbf;
$border-light:#f5f5f8;
$shadow-light:#e2e2e2;
$shadow-light2:#efecec;
$hash-color:#bebebe;
$lighten-white:#eeeeee;
$lighten-white2:#f1f1f1;
$light-white:#f7f7f7;
$white2:#dbdbdb;
$white3:#c1c0c0;
$white4:#eee;
$white5:#ced4da;
$white6:#f0eeee;
$white7:#c7c7c7;
$white8:#fafafb;
$white9:#f5f5f5;
$white10:#bdbdbd;
$white11:#eceaea;
$border-white:#dddddd;
$light-inner:#3498db;
$lighten-yellow:#979154;
$lighten-yellow2:#ffe3a9;
$yellow:#ffa200;
$yellow2:#ffd200;
$yellow3:#dce400;
$yellow4:#c0882f;
$yellow5:#ECC878;
$yellow-metro: #dfbb04;
$blue2:#41a3ff;
$blue3:#3c84ad;
$blue4:#0f2469;
$blue5:#0072bb;
$blue6: #357fef;
$light-gray:#888787;
$gray: #808080;
$gray2:#535e72;
$gray3:#464545;
$gray4:#7b7b7c;
$gray5:#949494;
$gray6:#595959;
$gray7:#9c9c9c;
$gray8:#b1b1b1;
$gray9:#8f8e94;
$gray10:#333333;
$gray11:#6f6f6f;
$gray12:#acacac;
$gray13:#e3e3e3;
$gray14:#aaaaaa;
$gray15:#777;
$gray16:#666666;
$gray17:#212121;
$gray18:#757575;
$gray19:#2d2d2d;
$heart:#fb3b64;
$copyright-app2:#f0665f;
$copyright-agency:#1e347c;
$copyright-saas1:#061625;
$copy-text-resume:#989a9f;
$copy-bg:#0c0c0c;
$black2:#0e0e0e;
$music-dark-bg:#591e1e;
$red2:#61131f;
$red3:#e3154f;
$alert-primary:#004085;
$alert-secondary:#383d41;
$alert-success:#155724;
$alert-info:#0c5460;
$alert-warning:#856404;
$alert-danger:#721c24;
$alert-dark:#1b1e21;
$success:#35b53e;
$border-blog:#f3f3f3;
$color-creative1:#ef6767;
$bg-container:#131313;

$color2:#f3e5e4;
$color3:#d5d5ec;
$color4:#bcecf7;
$color5:#fff2cf;
$color6:#ffcfcf;
$color7:#d1d1d1;

$music-light: #233e80;
$music-dark: #1c255a;

$color-blue:#4a71ef;
$round-border: #dddddd;
$font-grey: #aaaaaa;
$dark-font: #222222;
$grey: #777777;
$theme-deafult: #000;
$grey-about: #f7f7f7;
$font-color:#333333;
$star-yellow: #ffa200;

