@import url(https://fonts.googleapis.com/css?family=Capriola&amp;subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Work+Sans:100,200,300,400,500,600,700,800);
@import "./node_modules/bootstrap/scss/bootstrap";
//@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
//@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import "font-awesome.scss";
@import "themify.scss";
@import "flaticon.scss";
@import 'color-1.scss';
//@import "../css/keyframes.css";
