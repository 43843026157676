//Here You can write your customize scss
header nav ul li a {
	padding: 8px!important;
	font-family:"Arial Rounded MT Bold","Helvetica Rounded", Arial, sans-serif;
	
}

header.ecommerce nav {
	justify-content: center;
}

@media only screen and (min-width: 992px) {
	header nav ul li a {
		font-size: 16px!important;
		font-family:"Arial Rounded MT Bold","Helvetica Rounded", Arial, sans-serif;
	}
}

header.ecommerce nav ul.main-menu > li > a {
		color: #3498db;
		font-weight: bold;
	}

@media only screen and (max-width: 991px) {
	header nav ul li a {
		font-size: 15px!important;
	}
}

.agency h2 {
	color: #ffffff!important;
}

.white {
	color: #ffffff!important;
}

@media only screen and (min-width: 992px) {
	header.ecommerce nav ul.main-menu > li > a {
		margin-top: 25px!important;
		margin-bottom: 10px;
	}
}

.breadcrumb-section-main.breadcrumb-section-sm {
	padding: 30px 0;
}

